import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Document, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString()

import { Button } from '@genericComponents/Button/Button'
import { Icon } from '@genericComponents/Icon/Icon'
import { PageRenderer } from './PageRenderer'
import { Container, Navigation, NavigationInner } from './PdfViewer.styles'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { observer } from 'mobx-react'
import { StoreContext } from '@/components/App'
import { devLog } from '@/methods/devLog'
import { base64ToBlob } from '@/methods/base64ToBlob'
import { Indicator } from './Indicator'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'
import { DownloadDocumentButton } from '@/components/DownloadDocumentButton'

export interface InitFunctionProps {
  nextPage: MutableRefObject<undefined | (() => void)>
  prevPage: MutableRefObject<undefined | (() => void)>
  zoomIn: MutableRefObject<undefined | (() => void)>
  zoomOut: MutableRefObject<undefined | (() => void)>
}

interface PdfViewerProps {
  file: string
  fileId?: string
  documentClassName?: string
  pageClassName?: string
  viewerWidth?: string
  viewerHeight?: string | number
  containerHeight?: string | number
  initialScale?: number
  onDocumentLastPage?: () => void
  hideZoomControls?: boolean
  documentDownloadEnabled?: boolean

  controls?: {
    shouldZoomIn?: boolean
    shouldZoomOut?: boolean
    setShouldZoomIn?: (value: boolean) => void
    setShouldZoomOut?: (value: boolean) => void
  }
}

export const PdfViewer = observer(
  ({
    file,
    fileId,
    documentClassName,
    pageClassName,
    viewerWidth,
    viewerHeight,
    containerHeight,
    initialScale,
    onDocumentLastPage,
    hideZoomControls,
    controls,
    documentDownloadEnabled,
  }: PdfViewerProps) => {
    const store = useContext(StoreContext)
    const { containerBorder, toolbox, zoomButton } =
      store.InterfaceState.theme.pdfViewer

    const zoomButtonProps = useMemo(
      () => ({
        ...zoomButton,
        border: '1px solid' + zoomButton.borderColor,
        borderHover: '1px solid' + zoomButton.borderColorHover,
        borderRadius: '50%',
        width: '44px',
        height: '44px',
        widthMobile: '44px',
        heightMobile: '44px',
        paddingMobile: '0px',
        padding: '0px',
      }),
      [zoomButton]
    )

    const [numPages, setNumPages] = useState<number>()
    const [pageScale, setPageScale] = useState<number>(1.0)

    const cachedFile = useMemo(() => file, [file])
    const cachedClassName = useMemo(
      () => documentClassName,
      [documentClassName]
    )
    const cachedOptions = useMemo(() => ({ enableHWA: true }), [])
    const onDocumentLoadSuccess = useCallback(
      ({ numPages }: pdfjs.PDFDocumentProxy) => {
        setNumPages(numPages)
      },
      []
    )

    useEffect(() => {
      if (initialScale) {
        setPageScale(initialScale)
      }
    }, [])

    useEffect(() => {
      if (controls?.shouldZoomIn) {
        setPageScale(pageScale + 0.2)
        controls?.setShouldZoomIn(false)
      }
    }, [controls?.shouldZoomIn])

    useEffect(() => {
      if (controls?.shouldZoomOut) {
        setPageScale(pageScale - 0.2)
        controls?.setShouldZoomOut(false)
      }
    }, [controls?.shouldZoomOut])

    const InnerWrapRef = useRef<HTMLDivElement>(null)
    const [innerHeight, setInnerHeight] = useState<string | number>(
      containerHeight || viewerHeight
    )

    useEffect(() => {
      if (containerHeight) {
        setInnerHeight(containerHeight)
      }

      if (viewerHeight && typeof viewerHeight === 'number') {
        setInnerHeight(viewerHeight)
      }

      if (viewerHeight && typeof viewerHeight === 'string') {
        setInnerHeight(`${InnerWrapRef.current.clientHeight}px`)
      }
    }, [containerHeight, viewerHeight])

    const [pageRendererKey, setPageRendererKey] = useState(0)

    const [innerWrapRefDimensions, setInnerWrapRefDimensions] = useState({
      width: 0,
      height: 0,
    })

    useEffect(() => {
      if (InnerWrapRef?.current) {
        const { width, height } = InnerWrapRef.current.getBoundingClientRect()
        setInnerWrapRefDimensions({ width, height })
        setPageRendererKey((prev) => prev + 1)
      }
    }, [InnerWrapRef.current])

    const [currentPageIndex, setCurrentPageIndex] = useState(1)

    useEffect(() => {
      if (numPages === 1) {
        devLog('single page doc')
        onDocumentLastPage && onDocumentLastPage()
      }
    }, [currentPageIndex, numPages, fileId])

    const [shouldShowIndicator, setShouldShowIndicator] = useState(false)

    const handlePageIndicatorVisibility = useCallback(() => {
      setShouldShowIndicator(true)
    }, [])

    const [pdfForDownload, setPdfForDownload] = useState<Blob>(null)

    useEffect(() => {
      if (file && documentDownloadEnabled) {
        const pdfBlob = base64ToBlob(file, 'application/pdf')
        setPdfForDownload(pdfBlob)
      }
    }, [file, documentDownloadEnabled])

    return (
      <>
        {documentDownloadEnabled && (
          <DownloadDocumentButton
            margin="0 20px 0 0"
            blobDoc={pdfForDownload}
          />
        )}
        <Container
          border={containerBorder}
          viewerWidth={viewerWidth}
          viewerHeight={containerHeight || viewerHeight}
          ref={InnerWrapRef}
        >
          <Document
            file={cachedFile}
            onLoadSuccess={onDocumentLoadSuccess}
            className={cachedClassName}
            options={cachedOptions}
            onLoadStart={() => setNumPages(undefined)}
            loading={
              <LoadingSpinner
                width="68px"
                padding="100px 0"
                {...store.InterfaceState.theme.loadingSpinner}
              />
            }
          >
            <PageRenderer
              key={'page-renderer' + pageRendererKey}
              pdfViewerTheme={store.InterfaceState.theme.pdfViewer}
              numPages={numPages}
              pageClassName={pageClassName}
              canvasWidth={innerWrapRefDimensions.width - 20}
              pageScale={pageScale}
              viewerWidth={viewerWidth}
              viewerHeight={innerHeight}
              containerDimensions={innerWrapRefDimensions}
              onDocumentLastPage={onDocumentLastPage}
              setCurrentPageIndex={setCurrentPageIndex}
              handlePageIndicatorVisibility={handlePageIndicatorVisibility}
            />
          </Document>

          <Indicator
            currentPage={currentPageIndex}
            totalPages={numPages}
            shouldShow={shouldShowIndicator}
            setShouldShow={setShouldShowIndicator}
          />

          {!hideZoomControls && (
            <Navigation>
              <NavigationInner
                bgColor={toolbox.bgColor}
                borderRadius={toolbox.borderRadius}
                padding={toolbox.padding}
                boxShadow={toolbox.boxShadow}
              >
                <Button
                  onClick={() => setPageScale(pageScale - 0.2)}
                  {...zoomButtonProps}
                >
                  <Icon type="zoom-out" size="30px" {...zoomButton} />
                </Button>

                <Button
                  onClick={() => setPageScale(pageScale + 0.2)}
                  {...zoomButtonProps}
                >
                  <Icon type="zoom-in" size="30px" {...zoomButton} />
                </Button>
              </NavigationInner>
            </Navigation>
          )}
        </Container>
      </>
    )
  }
)
