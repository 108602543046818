type Browser =
  | 'unknown'
  | 'chrome'
  | 'firefox'
  | 'safari'
  | 'edge'
  | 'vivaldi'
  | 'yandex'
  | 'opera'
  | 'brave'
  | 'samsung'
type Engine = 'unknown' | 'chromium' | 'firefox' | 'safari'
type System = 'unknown' | 'windows' | 'mac' | 'linux' | 'ios' | 'android'

const getUserAgent = () => {
  let browser: Browser = 'unknown'
  let engine: Engine = 'unknown'
  let system: System = 'unknown'
  let isMobile: boolean = false

  const userAgent = window.navigator.userAgent

  const isChromium = /Chrome/.test(userAgent)
  const isFirefox = /Firefox/.test(userAgent) || /FxiOS/i.test(userAgent)
  const isSafari = /Safari/.test(userAgent) && !isChromium && !isFirefox

  const isWindows = /Windows/.test(userAgent)
  const isMac = /Macintosh/.test(userAgent)
  const isLinux = /Linux/.test(userAgent)

  const isIOS =
    /iPhone/.test(userAgent) || /iPad/.test(userAgent) || /iPod/.test(userAgent)
  const isAndroid = /Android/.test(userAgent)

  const isEdge = /Edg/.test(userAgent)
  const isOpera =
    /OPR/.test(userAgent) || /OPiOS/i.test(userAgent) || /OPT/.test(userAgent)
  const isYandex = /YaBrowser/.test(userAgent)
  const isVivaldi = /Vivaldi/.test(userAgent)
  const isBrave = /Brave/.test(userAgent)
  const isSamsungBrowser = /SamsungBrowser/.test(userAgent)
  const isChrome =
    isChromium &&
    !isEdge &&
    !isOpera &&
    !isYandex &&
    !isVivaldi &&
    !isBrave &&
    !isSamsungBrowser

  if (isChromium) {
    engine = 'chromium'
  } else if (isFirefox) {
    engine = 'firefox'
  } else if (isSafari) {
    engine = 'safari'
  }

  if (isWindows) {
    system = 'windows'
  } else if (isMac) {
    system = 'mac'
  } else if (isLinux) {
    system = 'linux'
  }

  if (isIOS) {
    system = 'ios'
    isMobile = true
  } else if (isAndroid) {
    system = 'android'
    isMobile = true
  }

  if (isEdge) {
    browser = 'edge'
  } else if (isOpera) {
    browser = 'opera'
  } else if (isYandex) {
    browser = 'yandex'
  } else if (isVivaldi) {
    browser = 'vivaldi'
  } else if (isChrome) {
    browser = 'chrome'
  } else if (isFirefox) {
    browser = 'firefox'
  } else if (isSafari) {
    browser = 'safari'
  } else if (isBrave) {
    browser = 'brave'
  } else if (isSamsungBrowser) {
    browser = 'samsung'
  }

  console.log('userAgent', {
    engine,
    system,
    isMobile,
    browser,
  })

  return {
    engine,
    system,
    isMobile,
    browser,
  }
}

export default getUserAgent
