// Function to convert base64 to a Blob
export const base64ToBlob = (
  base64: string,
  contentType: string = '',
  sliceSize: number = 512
) => {
  // Remove potential data URI prefix
  const base64String = base64.split(',')[1] || base64

  // Check if padding is needed
  const base64Len = base64String.length
  if (base64Len % 4 > 0) {
    throw new Error('Invalid Base64 string')
  }

  // Decode Base64
  const byteCharacters = atob(base64String)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}
